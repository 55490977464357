import React from "react"
import { Link } from "gatsby"
import { StaticQuery, graphql } from "gatsby"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import { CgChevronRight } from "react-icons/cg"

import BlogCard from "./BlogCard"

const LatestPosts = ({ currentPageSlug }) => (
  <StaticQuery
    query={graphql`
      {
        allContentfulArticle(
          limit: 4
          sort: { fields: articlePublishedDate, order: DESC }
          filter: { node_locale: { eq: "uk" } }
        ) {
          nodes {
            id
            articleTitle
            articlePublishedDate(formatString: "DD.MM.YYYY")
            articleExcerpt {
              childMarkdownRemark {
                excerpt(pruneLength: 150, truncate: false, format: PLAIN)
              }
            }
            seo {
              slug
              title
              metaDescription
            }
            articleImage {
              title
              description
              fluid(maxWidth: 1024) {
                ...GatsbyContentfulFluid_withWebp
              }
            }
            articleCategory {
              articleCategoryName
              id
            }
          }
        }
      }
    `}
    render={data => (
      <section className="news my-5 py-5">
        <Container className="w-100">
          <div className="h3 mb-5 gradient-text blog-header">
            Останні новини
          </div>
          <Link to="/blog/" className="blog-header-link ml-2 ml-lg-4">
            до блога
            <CgChevronRight />
          </Link>
          <Row>
            {/* Get only 3 unique posts no self refference */}
            {data.allContentfulArticle.nodes
              .filter(article => article.seo.slug !== currentPageSlug)
              .map((article, index) => {
                if (index < 3) {
                  return (
                    <BlogCard
                      key={article.id}
                      articleImage={article.articleImage.fluid}
                      articleTitle={article.articleTitle}
                      articlePublishedDate={article.articlePublishedDate}
                      articleExcerpt={
                        article.articleExcerpt.childMarkdownRemark.excerpt
                      }
                      articleSlug={article.seo.slug}
                      articleCategories={article.articleCategory}
                    />
                  )
                }
                return null
              })}
          </Row>
        </Container>
      </section>
    )}
  />
)

LatestPosts.defaultProps = {
  currentPageSlug: ``,
}

export default LatestPosts
