import React from "react"
import { graphql } from "gatsby"
// import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"

import Layout from "../components/layout/basic"
import SEO from "../components/global/SEO"
import TariffGlobal from "../components/TariffGlobalCard"
import Reasons from "../components/reasons"
// import Tv from "../components/telebachennya"
import Tv from "../components/telebachennya-light"
import OfferCarousel from "../components/OfferCarousel"
import LatestPosts from "../components/blog/LatestPosts"

export default ({ data: { logo, cottage, flat, store, kidimage, offers } }) => {
  // const backgroundImage = [
  //   kidimage.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.6), rgba(15, 1, 94, 0.75))`,
  // ].reverse()

  return (
    <Layout>
      <SEO
        title="Інтернет-Провайдер Новий Телеком"
        description="Підключити Інтернет і Телебачення від Інтернет-Провайдера Новий Телеком (Newtelecom) в селах Київської області. Бучанський район (Київо-Святошинський), інтернет по Житомирській трасі"
        image=""
        url="/"
      />
      <div className="py-5 hero-background-light">
        <Container className="py-5">
          <Row>
            <Col lg={9} md={12} sm={12}>
              <h1 className="hero-title">
                Інтернет провайдер{" "}
                <span className="newtele-brandname">Новий Телеком</span>
              </h1>
              <div className="hero-slogan mt-4">
                Безлімітний Інтернет і Телебачення в будинок, квартиру або для
                бізнесу.
                <br />
                Швидке підключення в Києво-Святошинському (Бучанському) та
                Макарівському районах Київської області.
              </div>
            </Col>
            <Col
              lg={3}
              md={0}
              sm={0}
              className="d-none d-lg-block hero-logo-bg"
            >
              <div className="hero-logo-clip">
                <svg xmlns="http://www.w3.org/2000/svg" width="0" height="0">
                  <defs>
                    <clipPath id="newteleClip">
                      <path
                        id="svgSelection"
                        fill="none"
                        stroke="black"
                        strokeWidth="1"
                        d="M 97.80,197.56
                        C 96.68,196.58 95.82,194.94 95.02,193.66
                          95.02,193.66 90.63,186.59 90.63,186.59
                          85.53,178.08 80.71,169.35 76.28,160.49
                          63.71,135.34 53.19,107.48 48.19,79.76
                          46.34,69.53 44.89,58.43 44.88,48.05
                          44.88,48.05 44.63,43.90 44.63,43.90
                          44.63,43.90 44.63,42.93 44.63,42.93
                          44.63,42.93 44.88,39.76 44.88,39.76
                          44.88,39.76 44.88,36.10 44.88,36.10
                          44.88,36.10 45.10,33.17 45.10,33.17
                          45.10,33.17 45.82,24.39 45.82,24.39
                          45.95,23.25 46.32,19.13 46.83,18.37
                          47.24,17.74 49.27,16.59 50.00,16.17
                          50.00,16.17 57.56,12.13 57.56,12.13
                          68.78,6.56 80.95,3.51 93.41,2.66
                          93.41,2.66 95.85,2.44 95.85,2.44
                          95.85,2.44 102.44,2.44 102.44,2.44
                          102.44,2.44 105.12,2.64 105.12,2.64
                          106.43,2.77 107.75,2.70 108.04,4.39
                          108.17,5.15 107.27,7.45 106.99,8.29
                          106.99,8.29 104.36,16.83 104.36,16.83
                          101.69,26.96 99.46,37.17 98.02,47.56
                          96.65,57.39 95.54,67.18 94.86,77.07
                          94.86,77.07 94.15,89.27 94.15,89.27
                          94.15,89.27 93.89,92.44 93.89,92.44
                          93.89,92.44 93.89,96.10 93.89,96.10
                          93.89,96.10 93.66,99.02 93.66,99.02
                          93.66,99.02 93.66,106.10 93.66,106.10
                          93.66,106.10 93.41,109.76 93.41,109.76
                          93.41,109.76 93.41,124.88 93.41,124.88
                          93.41,124.88 93.66,128.78 93.66,128.78
                          93.66,128.78 93.66,136.10 93.66,136.10
                          93.66,136.10 93.90,140.24 93.90,140.24
                          93.90,140.24 94.15,148.78 94.15,148.78
                          94.15,148.78 94.39,152.93 94.39,152.93
                          94.39,152.93 94.64,158.54 94.64,158.54
                          94.64,158.54 94.88,160.98 94.88,160.98
                          94.88,160.98 94.88,163.17 94.88,163.17
                          94.88,163.17 95.34,168.54 95.34,168.54
                          95.34,168.54 96.38,183.17 96.38,183.17
                          96.38,183.17 97.80,197.56 97.80,197.56 Z
                        M 114.63,3.66
                        C 116.76,3.76 121.47,4.85 123.66,5.40
                          130.50,7.11 137.10,9.47 143.41,12.62
                          169.18,25.51 187.74,48.39 194.73,76.34
                          196.12,81.90 196.95,87.46 197.34,93.17
                          197.34,93.17 197.56,95.61 197.56,95.61
                          197.56,95.61 197.56,103.90 197.56,103.90
                          197.56,103.90 197.34,106.83 197.34,106.83
                          196.69,116.20 194.76,125.33 191.48,134.15
                          191.48,134.15 187.86,142.68 187.86,142.68
                          187.16,144.11 186.52,145.70 185.37,146.83
                          185.37,146.83 184.18,136.59 184.18,136.59
                          183.11,128.95 181.60,121.38 179.73,113.90
                          172.24,83.96 159.51,57.01 140.76,32.44
                          136.25,26.53 130.79,19.94 125.61,14.63
                          125.61,14.63 118.05,7.07 118.05,7.07
                          118.05,7.07 114.63,3.66 114.63,3.66 Z
                        M 123.41,30.98
                        C 123.41,30.98 123.90,30.98 123.90,30.98
                          123.90,30.98 126.28,35.61 126.28,35.61
                          126.28,35.61 130.42,43.90 130.42,43.90
                          134.63,52.59 138.51,61.48 142.00,70.49
                          149.54,89.95 155.64,111.46 158.22,132.20
                          158.22,132.20 159.29,143.90 159.29,143.90
                          159.29,143.90 159.51,146.59 159.51,146.59
                          159.51,146.59 159.76,151.71 159.76,151.71
                          159.76,151.71 159.76,161.71 159.76,161.71
                          159.76,161.71 159.51,165.37 159.51,165.37
                          159.51,165.37 159.03,172.44 159.03,172.44
                          158.98,173.36 158.64,177.40 158.29,178.00
                          157.86,178.74 155.45,180.27 154.63,180.81
                          151.47,182.92 148.28,184.94 144.88,186.65
                          134.01,192.09 124.06,195.42 111.95,196.87
                          111.95,196.87 103.90,197.56 103.90,197.56
                          103.90,197.56 99.27,197.56 99.27,197.56
                          99.27,197.56 99.51,193.90 99.51,193.90
                          99.51,193.90 99.76,185.12 99.76,185.12
                          99.76,185.12 100.00,179.51 100.00,179.51
                          100.00,179.51 100.24,175.61 100.24,175.61
                          100.24,175.61 100.49,170.98 100.49,170.98
                          100.49,170.98 100.95,163.66 100.95,163.66
                          100.95,163.66 101.48,154.39 101.48,154.39
                          101.48,154.39 103.86,128.29 103.86,128.29
                          106.60,100.87 111.85,67.21 120.01,40.98
                          120.01,40.98 123.41,30.98 123.41,30.98 Z
                        M 18.29,46.59
                        C 18.81,47.84 18.91,51.60 19.07,53.17
                          19.07,53.17 21.00,67.56 21.00,67.56
                          23.32,81.60 26.39,93.63 31.06,107.07
                          39.43,131.19 52.47,153.93 68.64,173.66
                          68.64,173.66 80.23,186.59 80.23,186.59
                          80.23,186.59 82.68,189.27 82.68,189.27
                          82.68,189.27 90.73,197.07 90.73,197.07
                          88.40,197.07 84.57,196.38 82.20,195.96
                          74.07,194.49 66.53,192.05 59.02,188.61
                          49.80,184.39 41.09,178.61 33.66,171.69
                          25.64,164.21 18.95,155.49 13.73,145.85
                          -3.06,114.80 -0.93,76.01 18.29,46.59 Z"
                      />
                    </clipPath>
                  </defs>
                </svg>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* <BackgroundImage
        Tag="header"
        fluid={backgroundImage}
        className="pb-5 hero-screen"
      >
        <Container className="py-5">
          <h1 className="hero-title">Інтернет провайдер Новий Телеком</h1>
          <div className="hero-slogan mt-4">
            Безлімітний інтернет і телебачення в будинок, квартиру або для
            бізнесу.
            <br />
            Швидке підключення в Києво-Святошинському (Бучанському) та
            Макарівському районах Київської області.
          </div>
        </Container>
      </BackgroundImage> */}
      <section className="tarifs mt-5">
        <Container>
          <Row>
            <TariffGlobal
              tariffGName="Інтернет в Будинок"
              tariffGPrice="300"
              tariffGSpeed="1000"
              tariffGImageFluid={cottage.fluid}
              tariffGImageAlt="будинок"
              tariffGURL="/internet-v-budynok/"
            />
            <TariffGlobal
              tariffGName="Інтернет в квартиру"
              tariffGPrice="150"
              tariffGSpeed="100"
              tariffGImageFluid={flat.fluid}
              tariffGImageAlt="квартира"
              tariffGURL="/internet-v-kvartyru/"
            />
            <TariffGlobal
              tariffGName="Інтернет для бізнесу"
              tariffGPrice="500"
              tariffGSpeed="1000"
              tariffGImageFluid={store.fluid}
              tariffGImageAlt="бізнес"
              tariffGURL="/internet-v-ofis/"
            />
          </Row>

          <Reasons />
        </Container>
      </section>
      <Tv />
      <OfferCarousel offers={offers.nodes} offerUrl="/promo/" />
      <LatestPosts />
    </Layout>
  )
}

export const query = graphql`
  {
    logo: contentfulAsset(id: { eq: "88427179-71b9-5c42-bf71-6e3a2128f053" }) {
      fixed(width: 400) {
        ...GatsbyContentfulFixed_withWebp
      }
      fluid(maxWidth: 200) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    cottage: contentfulAsset(title: { eq: "cottage" }) {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    flat: contentfulAsset(title: { eq: "flat" }) {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    store: contentfulAsset(title: { eq: "store" }) {
      fluid(maxWidth: 400) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    kidimage: contentfulAsset(
      id: { eq: "c139dded-e761-503b-b72f-999c8c59dbe7" }
    ) {
      id
      title
      fluid(maxWidth: 1920, quality: 85) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    router: contentfulAsset(
      id: { eq: "e1b534d0-b945-5abb-9ee7-53624fbda55a" }
    ) {
      id
      title
      fluid(maxWidth: 800) {
        ...GatsbyContentfulFluid_withWebp
      }
    }
    offers: allContentfulPromoOffer(filter: { node_locale: { eq: "uk" } }) {
      nodes {
        id
        title
        slogan {
          childMarkdownRemark {
            html
          }
        }
        offerImage {
          fluid(maxWidth: 1280) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    }
  }
`
