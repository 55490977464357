import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"

import { MdChevronRight } from "react-icons/md"

const TariffGlobalCard = ({
  tariffGName,
  tariffGPrice,
  tariffGSpeed,
  tariffGImageFluid,
  tariffGURL,
  tariffGImageAlt,
}) => (
  <Col xs={12} sm={12} md={6} lg={4} className="mb-4 mb-lg-0">
    <Card className="tariff-card">
      <Img
        fluid={tariffGImageFluid}
        className="card-img tariff-image img-responsive"
        alt={tariffGImageAlt}
      />
      <Card.ImgOverlay className="tariff-image-overlay d-flex align-items-center justify-content-center">
        <div className="tariff-title h5">{tariffGName}</div>
      </Card.ImgOverlay>
      <Card.Body className="text-center">
        <Card.Text as="div" className="mb-4 text-muted">
          <Row className="global-tariff-card-body">
            <Col md={6} sm={6} xs={6} className="mb-sm-3 mb-md-0">
              Ціна від <div className="tariff-price">{tariffGPrice}</div> грн на
              міс
            </Col>
            <Col md={6} sm={6} xs={6}>
              Швидкість до <div className="tariff-speed">{tariffGSpeed}</div>{" "}
              Мбіт/с
            </Col>
          </Row>
        </Card.Text>
        <Button
          as={Link}
          to={tariffGURL}
          variant="outline-newtele"
          className="d-inline-flex align-items-center justify-content-center my-2 text-uppercase btn-block"
        >
          <MdChevronRight /> Обрати тариф
        </Button>
      </Card.Body>
    </Card>
  </Col>
)

TariffGlobalCard.defaultProps = {
  tariffGImage: ``,
  tariffGName: `Інтернет`,
  tariffGSpeed: `100`,
  tariffGPrice: `120`,
  tariffGImageAlt: `обрати тариф`,
  tariffGURL: `/`,
}

export default TariffGlobalCard
