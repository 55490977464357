import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"

import Card from "react-bootstrap/Card"
// import Button from "react-bootstrap/Button"
import Col from "react-bootstrap/Col"
import { AiOutlineTag } from "react-icons/ai"

const BlogCard = ({
  articleImage,
  articleTitle,
  articlePublishedDate,
  articleExcerpt,
  articleSlug,
  articleCategories,
}) => (
  <Col lg={4} md={4} sm={6} xs={12} className="mb-5">
    <Card className="blogcard mb-4 h-100">
      <div className="card-image-wrap">
        <Card.Img
          as={Img}
          fluid={articleImage}
          alt={articleTitle}
          className="article-card-thumbimage"
        />
      </div>
      <Card.Body className="">
        <Card.Text
          as="div"
          className="mb-2 text-muted article-card-date d-flex justify-content-between"
        >
          <div>{articlePublishedDate}</div>
          <div>
            <AiOutlineTag />
            {` `}
            {articleCategories.map((category, index) => (
              <span key={category.id}>
                {(index ? ", " : "") + category.articleCategoryName}
              </span>
            ))}
          </div>
        </Card.Text>
        <Card.Title
          className="gradient-text blogcard-title w-100"
          as={Link}
          to={`/blog/${articleSlug}/`}
        >
          {articleTitle}
        </Card.Title>
        <Card.Text className="mt-2">{articleExcerpt}</Card.Text>
        {/* <Button
          as={Link}
          to={`/blog/${articleSlug}/`}
          variant="link"
          className="blogcard-btn mb-3 mr-3"
        >
          читати далі
        </Button> */}
        <Link to={`/blog/${articleSlug}/`} className="blogcard-btn mb-4 mr-4">
          читати далі
        </Link>
      </Card.Body>
    </Card>
  </Col>
)

BlogCard.defaultProps = {
  articleImage: `none`,
  articleTitle: `Заголовок статьи для карточки блога`,
  articlePublishedDate: `01.01.2020`,
  articleExcerpt: `Вводный текст статьи на несколько строк будет здесь`,
  articleSlug: `/`,
  articleCategories: [
    {
      articleCategoryName: `Новини`,
      id: `123456`,
    },
  ],
}

export default BlogCard
