import React from "react"
import { graphql, useStaticQuery, Link } from "gatsby"
import Img from "gatsby-image"
// import BackgroundImage from "gatsby-background-image"

import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
// import Button from "react-bootstrap/Button"

const TelebachennyaLight = () => {
  const { gadgets, tvproviders } = useStaticQuery(
    graphql`
      {
        tvproviders: allContentfulTvProvider(
          filter: { node_locale: { eq: "uk" } }
        ) {
          nodes {
            name
            id
            seo {
              slug
            }
            logo {
              title
              fixed(
                width: 150
                height: 80
                quality: 85
                resizingBehavior: PAD
              ) {
                ...GatsbyContentfulFixed_withWebp
              }
            }
          }
        }
        movies: contentfulAsset(
          id: { eq: "275930a9-41db-5c2b-aee0-405f8205fd9d" }
        ) {
          title
          fluid(maxWidth: 635) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
        gadgets: contentfulAsset(
          id: { eq: "6362560a-bf47-5cac-ad1a-7031e77a4b0d" }
        ) {
          title
          fluid(maxWidth: 635) {
            ...GatsbyContentfulFluid_withWebp
          }
        }
      }
    `
  )

  // const bgImage = [
  //   movies.fluid,
  //   `radial-gradient(at 50% 100%, rgba(181, 45, 168, 0.75), rgba(15, 1, 94, 0.85))`,
  // ].reverse()

  return (
    <section className="telebachennya-light mt-5 py-5">
      <Container className="my-5">
        <div className="h2 mb-5">
          <span className="colored-header">Необмежені</span> фільми, телешоу і
          телеканали
        </div>
        <Row>
          <Col className="telebachennya-bg pt-4 pb-4 pb-md-0" lg={7}>
            <Img
              fluid={gadgets.fluid}
              alt="телебачення на буд яких пристроях"
            />
          </Col>
          <Col
            className="telebachennya-text d-flex flex-column align-self-center"
            lg={5}
          >
            <p className="mb-3 colored-header lead-header">
              На будь-яких пристроях
            </p>
            <p>
              Дивись на планшетах Andoid або iOS, SMART телевізорах або з
              медіа-приставкою, на компьютері або з PlayStation, Xbox,
              Chromecast, Apple TV тощо...
            </p>
            <p className="mt-4 mb-3 colored-header lead-header">
              Провайдери телебачення
            </p>
            <Container>
              <Row>
                {tvproviders.nodes.map(provider => (
                  <Col
                    as={Link}
                    xs={6}
                    key={provider.id}
                    to={`/telebachennya/${provider.seo.slug}/`}
                    className="mb-3"
                  >
                    <Img
                      fixed={provider.logo.fixed}
                      alt={provider.logo.title}
                    />
                  </Col>
                ))}
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default TelebachennyaLight
